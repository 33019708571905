'use strict'

import customSelect from 'custom-select'

/**
 * <select>要素をカスタムします
 */
export default () => {
  const targets = document.querySelectorAll('.c-select select')
  const cstSelInstance = []

  if (targets) {
    for (let i = 0; i < targets.length; i = (i + 1) | 0) {
      const target = targets[i]
      const cstSel = customSelect(target)[0]
      cstSelInstance.push(cstSel)

      cstSel.select.addEventListener('change', e => {
        selectVlaueSync(targets, i, target.name, e.target.value)
      })
    }
  }

  /**
   * 他のselectのvalueを同期
   * @param {HTMLElement array} targets
   * @param {number} ignoreIndex
   * @param {string} name
   * @param {string} value
   */
  const selectVlaueSync = (targets, ignoreIndex, name, value) => {
    for (let i = 0; i < targets.length; i = (i + 1) | 0) {
      if (i !== ignoreIndex && name === targets[i].name) {
        cstSelInstance[i].value = value
      }
    }
  }
}
