'use strict'

import AutoComplete from '@tarekraafat/autocomplete.js'

/**
 * <input>要素のautocompleteを提供します
 * @link https://github.com/TarekRaafat/autoComplete.js
 */
export default () => {
  const nameList = [
    'サジェスチョン1',
    'サジェスチョン2',
    'サジェスチョン3'
  ]

  const targets = document.querySelectorAll('.c-input input')
  if (targets) {
    for (let i = 0; i < targets.length; i = (i + 1) | 0) {
      const target = targets[i]
      const parent = targets[i].parentNode

      const autoCompletejs = new AutoComplete({
        data: {
          // 現在は配列でデータを指定していますが、
          // api取得も可能です。（公式リファレンス参照）
          src: nameList
        },
        selector: () => target,
        trigger: {
          event: ['input', 'focus', 'blur'].forEach((e) => {
            target.addEventListener(e, () => {
              if (e === 'blur') {
                classNameControl(parent, false, 'is-hit')
              }
            })
          })
        },
        resultsList: {
          container: () => {
            classNameControl(parent, true, 'is-hit')
          }
        },
        noResults: () => {
          classNameControl(parent, false, 'is-hit')
        },
        onSelection: feedback => {
          target.blur()
          target.value = feedback.selection.value
          inputVlaueSync(targets, i, feedback.selection.value)

          console.log(feedback)
        }
      })
    }
  }

  /**
   * 他のinputのvalueを同期
   * @param {HTMLElement array} targets
   * @param {number} ignoreIndex
   * @param {string} value
   */
  const inputVlaueSync = (targets, ignoreIndex, value) => {
    for (let i = 0; i < targets.length; i = (i + 1) | 0) {
      if (i !== ignoreIndex) {
        const target = targets[i]
        target.value = value
      }
    }
  }

  /**
   * クラスネーム追加＆削除
   * @param {HTMLElement} target
   * @param {boolean} state
   * @param {string} string
   */
  const classNameControl = (target, state, string) => {
    if (state) {
      if (!target.classList.contains(string)) {
        target.classList.add(string)
      }
    }

    if (!state) {
      if (target.classList.contains(string)) {
        target.classList.remove(string)
      }
    }
  }
}
