'use strict'

import EL from '../constant/elements'
import getDeviceType from './getDeviceType'
import { throttle, debounce } from 'throttle-debounce'

/**
 * headerを下スクロールで非表示、上スクロールで表示の処理を提供します
 * @link https://firstlayout.net/scroll-down-and-scroll-up-with-javascript/
 */
export default () => {
  const func = {
    isTicking: false,
    deviceType: getDeviceType(),
    headerHeight: 0,
    offset: 0,
    lastScrollPosition: 0,

    /**
     * init
     */
    init: () => {
      func.getHeaderHeight()
      window.addEventListener('scroll', func.getScrollPosition, false)
      window.addEventListener('resize', func.resize, false)
      if (location.hash) {
        EL.HEADER.classList.add('is-animation')
      }
    },

    /**
     * getScrollPosition
     */
    getScrollPosition: () => {
      func.lastScrollPosition = window.scrollY || window.pageYOffset
      if (!func.isTicking) {
        window.requestAnimationFrame(() => {
          func.onScroll()
          func.isTicking = false
        })
        func.isTicking = true
      }
    },

    /**
     * onScroll
     */
    onScroll: () => {
      if (func.lastScrollPosition > func.headerHeight) {
        if (!EL.HEADER.classList.contains('is-animation') && !EL.HTML.classList.contains('is-menuopen')) {
          if (func.lastScrollPosition > func.offset + 10 && !EL.HTML.classList.contains('is-fixed')) {
            EL.HEADER.classList.add('is-animation')
            EL.HEADER.classList.remove('is-show')
          }
        } else {
          if (func.lastScrollPosition < func.offset - 10 && !EL.HTML.classList.contains('is-fixed')) {
            EL.HEADER.classList.remove('is-animation')
            EL.HEADER.classList.add('is-show')
          }
        }
        func.offset = func.lastScrollPosition
      }
      if (func.lastScrollPosition === 0) {
        EL.HEADER.classList.remove('is-animation')
        EL.HEADER.classList.remove('is-show')
      }
    },

    /**
     * getHeaderHeight
     */
    getHeaderHeight: () => {
      func.headerHeight = getDeviceType() === 'lg' ? 100 : 64
    },

    /**
     * resize
     */
    resize: debounce(150, () => {
      if (func.deviceType !== getDeviceType()) {
        func.deviceType = getDeviceType()
        func.getHeaderHeight()
      }
    })

  }

  func.init()
}
