'use strict'
import Swiper from 'swiper/bundle'
import { throttle, debounce } from 'throttle-debounce'

export default () => {
  const w = window.innerWidth
  let newsSlider
  let mvSlider
  let mvThumbSlider

  const initnewsSlider = () => {
    const w = window.innerWidth
    let slidesPerView = 3
    if (w > 1000) {
      slidesPerView = 3
    } else if (w > 768) {
      slidesPerView = 2
    } else {
      slidesPerView = 1
    }
    const slider = document.getElementById('top-news-slider')
    if (slider) {
      const slidenum = slider.querySelectorAll('.swiper-slide').length
      const slidable = slidenum > slidesPerView
      if (!slidable) {
        slider.classList.add('is-not-loop')
      } else {
        slider.classList.remove('is-not-loop')
      }
      newsSlider = new Swiper('#top-news-slider', {
        loop: slidable,
        watchOverflow: true,
        slidesPerView: slidesPerView,
        spaceBetween: 0,
        autoplay: {
          delay: 8000
        },
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true
        },
        breakpoints: {
          1000: {
            slidesPerView: slidesPerView,
            spaceBetween: 24
          },
          768: {
            slidesPerView: slidesPerView,
            spaceBetween: 24
          }
        }
      })
    }
  }
  initnewsSlider()

  const initMvThumbSlider = () => {
    mvThumbSlider = new Swiper('#top-mv-thumb-slider', {
      loop: false,
      slidesPerView: 5,
      spaceBetween: 5,
      slideToClickedSlide: true,
      pauseOnFocus: false,
      pauseOnHover: false,
      pauseOnDotsHover: false
    })
  }
  initMvThumbSlider()

  const initMvSlider = () => {
    mvSlider = new Swiper('#top-mv-slider', {
      loop: true,
      slidesPerView: 1,
      spaceBetween: 0,
      effect: 'fade',
      onlyExternal: true,
      autoplay: {
        delay: 8000
      },
      fadeEffect: {
        crossFade: true
      },
      thumbs: {
        swiper: mvThumbSlider
      }
    })
  }
  initMvSlider()

  window.addEventListener('resize', throttle(150, () => {
    if (newsSlider) {
      newsSlider.destroy()
      newsSlider = undefined
    }
    initnewsSlider()
    mvThumbSlider.init()
    mvSlider.init()
  }), false)
}
