'use strict'

import EL from '../constant/elements'
import { throttle, debounce } from 'throttle-debounce'

/**
 * ハンバーガーメニューの処理を行います
 */
export default () => {
  const func = {
    isActive: false,

    /**
     * init
     */
    init: () => {
      // if (EL.HMB && EL.HMBBG) {
      if (EL.HMB && EL.HMBBODY) {
        EL.HMB.addEventListener('click', func.switchShowHide, false)
        // EL.HMBBG.addEventListener('click', func.switchShowHide, false)
        window.addEventListener('resize', func.resize, false)
      }
    },

    /**
     * show
     */
    show: () => {
      func.isActive = true
      EL.HTML.classList.add('is-nav-active')
      EL.HTML.classList.add('is-fixed')
      EL.HMB.classList.add('is-open')
      EL.HMBBODY.classList.add('is-open')
    },

    /**
     * hide
     */
    hide: () => {
      func.isActive = false
      EL.HTML.classList.remove('is-nav-active')
      EL.HTML.classList.remove('is-fixed')
      EL.HMB.classList.remove('is-open')
      EL.HMBBODY.classList.remove('is-open')
    },

    /**
     * switchShowHide
     */
    switchShowHide: () => {
      func.isActive ? func.hide() : func.show()
    },

    /**
     * resize
     */
    resize: debounce(150, () => {
      if (func.isActive && window.innerWidth > 767) {
        func.hide()
      }
    })

  }

  func.init()
}
