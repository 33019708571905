'use strict'

// babel polyfill
import '@babel/polyfill'
import 'core-js/stable'

// define
import EL from './constant/elements'

// helper
import hmb from './helper/hmb'
import uaDataset from './helper/uaDataset'
import sweetScrollInit from './helper/sweetScrollInit'
import ieSmoothScrollDisable from './helper/ieSmoothScrollDisable'
import getTouchSupport from './helper/getTouchSupport'
import navCurrent from './helper/navCurrent'
import getDocumentH from './helper/getDocumentHeight'
import getOrientation from './helper/getOrientation'
import getClassName from './helper/getClassName'
import navAccordion from './helper/navAccordion'
import addAnimationClass from './helper/addAnimationClass'
import set100vh from './helper/set100vh'
import headerToggle from './helper/headerToggle'
import customSelect from './helper/customSelect'
import inputAutoComplete from './helper/inputAutoComplete'

// module
import Dropdown from './modules/Dropdown'
import MatchHeight from './modules/MatchHeight'

// plugins
import objectFitImages from 'object-fit-images'
import picturefill from 'picturefill'
import Stickyfill from 'stickyfilljs'
import { throttle, debounce } from 'throttle-debounce'
import ScrollBooster from 'scrollbooster/dist/scrollbooster.min'
import StickySidebar from 'sticky-sidebar'
import Swiper from 'swiper/bundle'
import $ from 'jquery'
import modaal from '../../../node_modules/modaal/dist/js/modaal.min.js'

// page scripts
import pageNameTop from './page/top'

// require
require('intersection-observer')
require('focus-visible')
require('svgxuse')
// require('./modules/JQueryModules')

$('.js-modal-image').modaal({
  type: 'image'
})

/**
 * getScrollPos
 */
const getScrollPos = () => {
  const y = window.pageYOffset
  const offset = 100
  const footerH = EL.FOOTER.clientHeight
  const documentH = getDocumentH()

  // add class is-scroll
  if (y > offset) {
    if (!EL.HTML.classList.contains('is-scroll')) {
      EL.HTML.classList.add('is-scroll')
    }
  } else {
    EL.HTML.classList.remove('is-scroll')
  }

  // add class is-footer
  if (documentH - footerH <= y) {
    if (!EL.HTML.classList.contains('is-footer')) {
      EL.HTML.classList.add('is-footer')
    }
  } else {
    EL.HTML.classList.remove('is-footer')
  }
}

/**
 * setDropDown
 */
const setDropDown = () => {
  const acordionEl = document.querySelectorAll('[data-accordion="el"]')
  acordionEl.forEach(
    el => {
      const ac = new Dropdown(el)
      ac.init()
    }
  )
}

/**
 * setTableIconFade
 */
const setTableIconFade = () => {
  const scTableEl = document.querySelectorAll('[data-sc-table="el"]')
  scTableEl.forEach(
    el => {
      el.addEventListener('scroll', () => {
        el.classList.add('is-animation')
        if (el.scrollLeft > 0) {
          el.classList.add('is-scroll')
        } else {
          el.classList.remove('is-scroll')
        }
      })

      const viewport = el
      const content = el.querySelector('table')
      const th = el.querySelector('thead > tr > th')

      if (content) {
        console.log(viewport, content)

        const viewportWidth = viewport.clientWidth
        const contentWidth = content.scrollWidth

        if (viewportWidth < contentWidth) {
          viewport.classList.add('has-scroll')
        }

        const sb = new ScrollBooster({
          viewport: viewport,
          content: content,
          scrollMode: 'native'
        })
      }
    }
  )
}

/**
 * setSwipeIconFade
 */
const setSwipeIconFade = () => {
  const scSwipeEl = document.querySelectorAll('[data-swipe="el"]')
  scSwipeEl.forEach(
    el => {
      el.addEventListener('scroll', () => {
        el.classList.add('is-animation')
        if (el.scrollLeft > 0) {
          el.classList.add('is-scroll')
        } else {
          el.classList.remove('is-scroll')
        }
      })

      const viewport = el
      const content = el.querySelector('[data-swipecon="el"]')

      if (content) {
        const viewportWidth = viewport.clientWidth
        const contentWidth = content.scrollWidth

        console.log(viewportWidth, contentWidth)

        if (viewportWidth < contentWidth) {
          viewport.classList.add('has-scroll')
        }

        const sb = new ScrollBooster({
          viewport: viewport,
          content: content,
          scrollMode: 'native'
        })
      }
    }
  )
}

/**
 * パンくずをスワイプさせる機能を提供します
 */
const breadcrumbScroller = () => {
  const target = document.querySelectorAll('.l-breadcrumbs')

  if (target) {
    for (let i = 0; i < target.length; i = (i + 1) | 0) {
      const item = target[i]
      const itemViewport = target[0]
      const itemContent = item.querySelector('.l-breadcrumbs__inner')

      const itemViewportWidth = itemViewport.clientWidth
      const itemContentWidth = itemContent.scrollWidth

      console.log(itemViewportWidth)
      console.log(itemContentWidth)

      if (itemViewport) {
        if (itemViewportWidth < itemContentWidth) {
          itemViewport.classList.add('has-scroll')
        }
      }
    }
  }
}

/**
 * firstRun
 */
const firstRun = () => {
  // set ua dataset
  uaDataset()

  // set touch support dataset
  getTouchSupport()

  // getOrientation
  getOrientation()

  // ie smoothScroll disable
  ieSmoothScrollDisable(true)

  // Polyfill object-fit
  objectFitImages()

  // Polyfill picturefill
  picturefill()
}

/**
 * initRun
 */
const initRun = () => {
  let sidebar
  // get body className
  const className = getClassName(EL.BODY)

  // add .is-loaded
  EL.HTML.classList.add('is-loaded')

  // stickyfilljs
  Stickyfill.add(EL.STICKY)

  // getScrollPos
  getScrollPos()

  // setDropDown
  setDropDown()

  // setTableIconFade
  setTableIconFade()

  // setSwipeIconFade
  setSwipeIconFade()

  // navCurrent
  navCurrent(EL.NAV)

  // hmb menu
  hmb()

  // customSelect
  customSelect()

  // inputAutoComplete
  inputAutoComplete()

  // headerToggle
  headerToggle()

  // set100vh
  set100vh()

  // navAccordion
  navAccordion()

  // sweetScroll
  sweetScrollInit()

  // breadcrumbScroller
  breadcrumbScroller()

  // addAnimationClass
  if (EL.ANIMATIONS) {
    addAnimationClass(EL.ANIMATIONS, '-40% 0px')
  }

  // anchorlink hide-header
  const linksToTop = document.querySelectorAll('[data-anchor="to-top"]')
  if (linksToTop) {
    linksToTop.forEach(el => {
      el.addEventListener('click', () => {
        setTimeout(() => {
          EL.HEADER.classList.add('is-animation')
        }, 1000)
      })
    })
  }

  // match height
  const matchHeightEl = document.querySelectorAll('[data-matchheight-area]')
  if (matchHeightEl) {
    matchHeightEl.forEach(
      el => {
        const mh = new MatchHeight(el)
        mh.init()
      }
    )
  }

  // match height
  const carouseles = document.querySelectorAll('[data-carousel]')
  if (carouseles.length) {
    carouseles.forEach(
      el => {
        const id = '#' + el.id
        const slider = new Swiper(id, {
          loop: true,
          slidesPerView: 1,
          spaceBetween: 0,
          pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          }
        })
      }
    )
  }

  if (className !== undefined) {
    // top
    if (className.endsWith('top')) {
      pageNameTop()
    }
  }

  const lNotice = document.getElementById('l-notice')
  const lNoticeClose = document.getElementById('l-notice-close')

  if (lNotice && lNoticeClose) {
    lNoticeClose.addEventListener('click', () => {
      lNotice.classList.add('is-hide')
      sidebar.updateSticky()
    })
  }

  // newsticker
  const initnNewsTicker = () => {
    const newsTicker = new Swiper('#newsticker', {
      loop: true,
      slidesPerView: 1,
      spaceBetween: 0,
      autoplay: {
        delay: 8000
      }
    })
  }
  initnNewsTicker()

  if (document.getElementsByClassName('l-nav').length) {
    sidebar = new StickySidebar('.l-nav', {
      containerSelector: '.l-page',
      innerWrapperSelector: '.l-nav__inner'
    })
    const mediaQuery = matchMedia('(min-width: 768px)')
    const mediaQueryHandler = mq => {
      if (mq.matches) {
        setTimeout(() => {
          sidebar.initialize()
        }, 100)
      } else {
        sidebar.destroy()
      }
    }
    mediaQueryHandler(mediaQuery)
    mediaQuery.addListener(mediaQueryHandler)
  }

  const lSearchInput = document.getElementById('l-search-input')
  const lSearchBtn = document.getElementById('l-search-btn')
  const lSearch = document.getElementById('l-search')
  const lSearchClose = document.getElementById('l-search-close')
  if (lSearchInput && lSearch && lSearchBtn && lSearchClose) {
    lSearchInput.addEventListener('focusout', (e) => {
      lSearch.classList.remove('is-active')
    })
    lSearchInput.addEventListener('focus', (e) => {
      lSearch.classList.add('is-active')
    })
    lSearchBtn.addEventListener('mouseover', (e) => {
      lSearch.classList.add('is-active')
    })
    lSearchClose.addEventListener('click', (e) => {
      lSearch.classList.remove('is-active')
    })
  }
}

/**
 * DOMCONTENTLOADED
 */
window.addEventListener('DOMContentLoaded', firstRun)

/**
 * LOAD
 */
window.addEventListener('load', initRun)

/**
 * SCROLL
 */
window.addEventListener('scroll', throttle(100, getScrollPos), false)
